export const accrochesPortes = [
    {
        name: "Accroches portes AQ",
        desc: "Les Accroches portes AQ offrent un fini semi-glacé qui complète toute conception.",
        img: "images/accroches_portes/aq.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt AQ (semi-glacé)"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "4.25 x 11",
                    "8.5 x 3.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "AQ glacé"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "1.25 Hole and Slit",
                    "BC Perf Hole and Slit"
                ]
            }
        ]
    },
    {
        name: "Accroches portes UV (luissant)",
        desc: "L’Accroche porte UV offre une finition éclatante pour un impact de marketing puissant.",
        img: "images/accroches_portes/uv.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt UV (luissant)"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "4.25 x 11",
                    "8.5 x 3.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "1.25 Hole and Slit",
                    "BC Perf Hole and Slit"
                ]
            }
        ]
    },
    {
        name: "Accroche-porte avec fini mat",
        desc: "Une excellente option pour les impressions à grand volume, les accroches portes avec fini mat offrent un fini lisse, élégant et à bas prix.",
        img: "images/accroches_portes/mat.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt avec fini mat"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "4.25 x 11",
                    "8.5 x 3.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "1.25 Hole and Slit"
                ]
            }
        ]
    },
    {
        name: "Accroche porte spécialisé",
        desc: "Les Accroches portes Enviro (sans enduits) sont une option qui est respectueuse de l’environnement et qui promouvoit la texture distinct du papier.",
        img: "images/accroches_portes/specialisees.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "13pt Enviro sans enduits"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "4.25 x 11",
                    "8.5 x 3.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "1.25 Hole and Slit"
                ]
            }
        ]
    }
]