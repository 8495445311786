export const livrets = [
    {
        name: "Livrets 8.5” x 11”",
        desc: "Nos livrets en gros de cette taille sont excellents pour les formats magazine, programme d’événements, guide de produits et toutes autres formats qui demandent beaucoup de texte.",
        img: "images/livrets/booklet_8.5x11.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "80lb Silk Text (2 côtés imprimés)",
                    "100lb Silk Text (2 côtés imprimés)",
                    "60lb Gloss Text (1 côté imprimé)",
                    "60lb Gloss Text (2 côtés imprimés)",
                    "80lb Gloss Text (2 côtés imprimés)",
                    "100lb Gloss Text (2 côtés imprimés)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8pg",
                    "12pg",
                    "16pg",
                    "20pg",
                    "24pg",
                    "28pg",
                    "32pg",
                    "36pg",
                    "40pg",
                    "44pg",
                    "48pg",
                    "52pg",
                    "56pg",
                    "60pg",
                    "64pg",
                ]
            },
            {
                specName: "Dimensions",
                values: [
                    "8.5\" x 11\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Couvertures",
                values: [
                    "Self Cover",
                    "14pt Cover"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "Bord long / Portrait",
                    "Bord court / paysage"
                ]
            }
        ]
    },
    {
        name: "Livrets 8.5” x 5.5””",
        desc: "Ces livrets en gros sont idéales comme menus, catalogues, et manuels d’instructions.",
        img: "images/livrets/booklet_8.5x5.5-portrait.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "80lb Silk Text (2 côtés imprimés)",
                    "100lb Silk Text (2 côtés imprimés)",
                    "60lb Gloss Text (1 côté imprimé)",
                    "60lb Gloss Text (2 côtés imprimés)",
                    "80lb Gloss Text (2 côtés imprimés)",
                    "100lb Gloss Text (2 côtés imprimés)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8pg",
                    "12pg",
                    "16pg",
                    "20pg",
                    "24pg",
                    "28pg",
                    "32pg",
                    "36pg",
                    "40pg",
                    "44pg",
                    "48pg",
                    "52pg",
                    "56pg",
                    "60pg",
                    "64pg",
                ]
            },
            {
                specName: "Dimensions",
                values: [
                    "8.5\" x 5.5\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Couvertures",
                values: [
                    "Self Cover",
                    "14pt Cover"
                ]
            },
            {
                specName: "Reliure",
                values: [
                    "Bord long / Portrait",
                    "Bord court / paysage"
                ]
            }
        ]
    },
]