export const bouteilles = [
    {
        name: "Bouteille en acier inoxydable 17oz personnalisé",
        desc: "Les bouteilles de marque sont l'article parfait pour les besoins de produits promotionnels de votre client. Vous pouvez maintenant obtenir ces bouteilles imprimées personnalisées à des prix de gros sans minimum de commande.",
        img: "images/bouteilles/left_1.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]