export const ballonsPublicitaires = [
    {
        name: "Ballons publicitaires",
        desc: "",
        img: "",
        specs: [
            
            {
                specName: "Dimensions",
                values: [
                    "12\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1000",
                    "2500",
                    "5000",
                ]
            },
            {
                specName: "Inclure support à ballon",
                values: [
                    "Oui",
                    "Non"
                ]
            }
            
            
        ]
    },
    
]