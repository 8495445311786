export const enveloppes = [
    {
        name: "Enveloppes sans enduits 60lb",
        desc: "Les enveloppes sans enduits sont offertes en trois variétés pour répondre aux besoins d'envoi.",
        img: "images/enveloppes/envelope-icon.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "60lb non-couché imprimé (1 côté)"
                ]
            },
            
            {
                specName: "Taille",
                values: [
                    "4.125 x 9.5 (#10 sans fenêtre)",
                    "4.125 x 9.5 (#10 avec fenêtre)",
                    "4.375 x 5.75",
                    "5.25 x 7.25",
                    "5.875 x 9",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Surface/Couverture",
                values: [
                    "1% - 25%",
                    "26% - 50%",
                    "51% - 75%",
                ]
            }
            
        ]
    },
    {
        name: "Enveloppes sans enduits 60lb Auto-adhésifs",
        desc: "Les enveloppes avec rabats autocollants permettent aux clients d'envoyer des correspondences d'affaires de façon professionnelle.",
        img: "images/enveloppes/envelope-icon.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "60lb auto-adhésif imprimé (1 côté)"
                ]
            },
            
            {
                specName: "Taille",
                values: [
                    "4.125 x 9.5 (#10 sans fenêtre)",
                    "4.125 x 9.5 (#10 avec fenêtre)",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Surface/Couverture",
                values: [
                    "1% - 25%",
                    "26% - 50%",
                    "51% - 75%",
                ]
            }
            
        ]
    },
    {
        name: "Enveloppes sans enduits 60lb Sécure",
        desc: "Les enveloppes avec rabats autocollants permettent aux clients d'envoyer des correspondences d'affaires de façon professionnelle.",
        img: "images/enveloppes/envelope-icon.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "60lb Sécure imprimé (1 côté)"
                ]
            },
            
            {
                specName: "Taille",
                values: [
                    "4.125 x 9.5 (#10 sans fenêtre)",
                    "4.125 x 9.5 (#10 avec fenêtre)",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Surface/Couverture",
                values: [
                    "1% - 25%",
                    "26% - 50%",
                    "51% - 75%",
                ]
            }
            
        ]
    },
]