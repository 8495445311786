export const canevas = [
    {
        name: "Rouleau Canevas",
        desc: "Le canevas est un matériel qui est utilisé typiquement pour les images photographiques et les oeuvres d'art",
        img: "images/canevas/Canvas.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Canevas imprimé 1 côté",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "12 x 24",
                    "12 x 36",
                    "12 x 48",
                    "24 x 24",
                    "24 x 36",
                    "24 x 48",
                    "36 x 36",
                    "36 x 48",
                    "48 x 48",
                    "60 x 12",
                    "60 x 24",
                    "60 x 36",
                    "60 x 48",
                    "72 x 12",
                    "72 x 24",
                    "72 x 36",
                    "72 x 48",
                    "84 x 12",
                    "84 x 24",
                    "84 x 36",

                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            {
                specName: "Pellicule blanche de 1 pouce",
                values: [
                    "Non",
                    "Oui",
                ]
            },
            
        ]
    },
    {
        name: "Canevas tendu",
        desc: "Ces impressions sur canevas personnalisés sont étirées sur des cadres en bois de 1,5 po fabriqués à partir de tilleul d'Amérique du Nord de haute qualité.",
        img: "images/canevas/tendu.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Canevas 15mil avec cadre en bois",
                ]
            },
            {
                specName: "Couleur",
                values: [
                    "Blanc"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8 x 8",
                    "12 x 12",
                    "12 x 16",
                    "12 x 18",
                    "12 x 24",
                    "16 x 12",
                    "16 x 16",
                    "16 x 20",
                    "16 x 24",
                    "18 x 12",
                    "18 x 24",
                    "20 x 16",
                    "20 x 24",
                    "24 x 12",
                    "24 x 16",
                    "24 x 18",
                    "24 x 20",
                    "24 x 24",
                    "24 x 36",
                    "36 x 24",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            
        ]
    },
]