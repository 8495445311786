export const cartesPerforees = [
    {
        name: "Cartes perforées UV (luissante)",
        desc: "Les cartes perforées UV offre une finition luissante pour faire démarquer votre marketing.",
        img: "images/cartes_perforees/luisante2.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt + UV (luissante)"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 11",
                    "4.25 x 11",
                    "8.5 x 3.5"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV"
                ]
            }
        ]
    },
    {
        name: "Cartes perforées avec fini mat",
        desc: "Avec une finition lisse, les cartes perforées avec fini mat ajoutent un touché unique au design et ce à un prix abordable.",
        img: "images/cartes_perforees/mat.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt avec fini mat"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 11",
                    "4.25 x 11",
                    "8.5 x 3.5"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            }
        ]
    },
    {
        name: "Cartes perforées spécialisées",
        desc: "Pour une texture de papier distincte, les cartes perforées Enviro sont respectueuses de l'environnement et sont imprimées sur du papier 100% recyclé.",
        img: "images/cartes_perforees/specialisees.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "13pt Enviro sans enduits"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 11",
                    "4.25 x 11",
                    "8.5 x 3.5"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            }
        ]
    }
]