import React from 'react'
import "../styles/Header.css"

export const Header = () => {
    return (
        <div className="header-logo-container">
            <a href="/">
                <img className="header-logo" src={require("../assets/printzonelogo2.png")}></img>
            </a>
            Laissez nos impressions vous impressionner!
        </div>
    )
}
