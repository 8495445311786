import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import "../styles/Modal.css"

export const Modal = (props) => {


    const [isAdded, setIsAdded] = useState(false);

    if (!props.open) return null;

    return (ReactDOM.createPortal(

        <div className="modal-overlay">

            <div className="modal-container">

                <div className="modal-title">{props.name}</div>

                <button className="modal-close-button" onClick={props.onClose}><i class="xmark fa-solid fa-xmark"></i></button>

                {/* selects and options for specs */}
                {
                    props.specs.map(spec => {
                        return (
                            <>
                                <label className="modal-label">{spec.specName}</label>

                                <select
                                    value={props.getStateValue(spec.specName)}
                                    id={spec.specName}
                                    className="modal-select"
                                    onChange={
                                        (e) => {
                                            
                                            props.updateProductStateAfterChange(spec.specName, e.target.value)
                                            
                                        }
                                    }
                                >
                                    <option disabled style={{ display: "none" }}></option>
                                    {
                                        spec.values.map(value => {
                                            return <option value={value}>{value}</option>
                                        })
                                    }
                                </select>

                            </>
                        )
                    })
                }

                <button onClick={() => {

                    props.onAdd(props.totalProductState)

                    setIsAdded(!isAdded)
                    props.onClose()
                    
                    props.setNumProdsInCart(props.numProdsInCart + 1)
                }
                } className="modal-add-button">
                    Ajouter au panier
                </button>
                
            </div>

        </div>,
        document.getElementById("portal")
    )
    )
}
