export const bannieresRetractables = [
    {
        name: "Les bannières rétractables - Standard",
        desc: "Doté d'un fini mat, la bannière adoucit la réflection, elle inclue un support standard durable.",
        img: "images/bannieres_retractables/standard-pull-up-banner-updated.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "13oz Matte Vinyl - pied noir - 31.5\" x 84.25\"",
                    "Vinyle mat 13oz - 33\" x 81\""
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                ]
            },
            {
                specName: "Inclure support en métal",
                values: [
                    "Oui"
                ]
            }
            
        ]
    },
    {
        name: "Les bannières rétractables - De luxe",
        desc: "Élevez l'apparence de la bannière rétractable de votre client avec un support de luxe de première qualité qui bénéficie d'une finition en aluminium argenté et des bouchons d'extrémité en chrome.",
        img: "images/bannieres_retractables/premium-regular.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle mat 13oz De luxe",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "33 x 81"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                ]
            },
            {
                specName: "Inclure support en métal",
                values: [
                    "Oui"
                ]
            }
            
        ]
    },
    {
        name: "Bannière rétractable pour table",
        desc: "Une bannière escamotable de dessus de table est une petite bannière de bureau en aluminium léger.",
        img: "images/bannieres_retractables/table-top-banner.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle mat 13oz",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "11 x 18"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                ]
            },
            {
                specName: "Inclure support en métal",
                values: [
                    "Oui"
                ]
            }
            
        ]
    },
    {
        name: "Bannière rétractable large de luxe",
        desc: "Notre bannière de traction personnalisée extra-large a plus de place sur les côtés. Ils sont livrés préinstallés dans des supports conçus pour les bannières larges avec une finition en aluminium argenté et des embouts chromés. Ce sont les meilleures bannières de salons professionnels à utiliser pour avoir un impact important.",
        img: "images/bannieres_retractables/premium-wide-banner.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle mat 13oz",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "48\" x 80.5\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                ]
            },
            {
                specName: "Inclure support en métal",
                values: [
                    "Oui"
                ]
            }
            
        ]
    },
    {
        name: "Bannière rétractable - imprimé 2 côtés",
        desc: "Les bannières rétractables imprimées sur mesure double face génèrent encore plus de visibilité. Ce type de bannière contextuelle consiste en deux bannières déroulantes installées dos à dos sur un seul support.",
        img: "images/bannieres_retractables/double-sided-banner.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle mat 13oz",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "33\" x 81\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                ]
            },
            {
                specName: "Inclure support en métal",
                values: [
                    "Oui"
                ]
            }
            
        ]
    },
]