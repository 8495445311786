export const tentesPromotionnelles = [
    {
        name: "Tente canopy",
        desc: "",
        img: "images/tentes_promotionnelles/tent.jpg",
        specs: [
            
            {
                specName: "Dimensions",
                values: [
                    "10\" x 10\"",
                    "15\" x 10\"",
                    "20\" x 10\"",
                    "5\" x 5\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            
            
        ]
    },
    
]