export const affichesCartonnees = [
    {
        name: "Affiches cartonnés",
        desc: "Ces affiches sont imprimées sur un papier cartonné semi-brillant.",
        img: "images/affiches_cartonnees/large-format-poster-printing.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "8pt C2S imprimé 1 côté",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "12 x 24",
                    "12 x 36",
                    "12 x 48",
                    "12 x 60",
                    "16 x 20",
                    "20 x 24",
                    "22 x 28",
                    "24 x 24",
                    "24 x 36",
                    "24 x 48",
                    "24 x 60",
                    "36 x 36",
                    "36 x 48",
                    "36 x 60",
                    "48 x 48",
                    "48 x 60",
                    "60 x 58",
                    "72 x 12",
                    "72 x 24",
                    "72 x 36",
                    "72 x 48",
                    "72 x 58",
                    "84 x 12",
                    "84 x 24",
                    "84 x 36",
                    "84 x 48",
                    "84 x 58",
                    "96 x 12",
                    "96 x 24",
                    "96 x 36",
                    "96 x 48",
                    "96 x 58",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            
        ]
    },
]