import './App.css';

import { useEffect, useState } from "react";

// pages
import { Home } from './components/pages/Home';
import { Contact } from './components/pages/Contact';
import { CartesDaffaire } from './components/pages/CartesDaffaire';

// components
import { NavbarComponent } from './components/NavbarComponent';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Carousel } from "react-responsive-carousel";

// react-router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// data
import { cartesDaffaire } from './data/cartesDaffaireData';

import "react-responsive-carousel/lib/styles/carousel.min.css";

// routes
import routes from './routes/routes';

function App() {

  // cart state
  const [cartItems, setCartItems] = useState([]);

  // state for number of products in cart
  const [numProdsInCart, setNumProdsInCart] = useState(0);

  // cart on add and on remove
  const onAdd = (product) => {
    setCartItems(cartItems.concat(product))
  }

  const onRemove = (product) => {
    const newCartItems = cartItems.filter(item => item != product)
    setCartItems(newCartItems)

    console.log("on remove fired")
  }

  return (
    <div className="app">

      <Router>

        <Header />
        <NavbarComponent numProdsInCart={numProdsInCart}/>

        <Routes>
          
          {routes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <route.component
                    cartItems={cartItems}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    data={route.data}
                    
                    numProdsInCart={numProdsInCart}
                    setNumProdsInCart={setNumProdsInCart}
                  />}>
              </Route>
            )
          })}

        </Routes>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
