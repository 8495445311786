export const pochettesCorporatives = [
    {
        name: "Pochettes standards avec fini mat",
        desc: "Un choix économique, les pochettes avec fini mat offre un léger éclat et un look professionnel. La languette standard est de 4”. Une languette optionnelle de 3” est disponible pour les pochettes 9” x 12” et  9” x 14.5”.",
        img: "images/pochettes_corporatives/matte-presentation-folder.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt Fini mat (standard)",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "5.25\" x 10.5\"",
                    "6\" x 9\"",
                    "9\" x 12\" - languette 3\"",
                    "9\" x 12\" - languette 4\"",
                    "9\" x 14.5\" - languette 3\"",
                    "9\" x 14.5\" - languette 4\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "1000",
                    "2500",
                    "5000",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            },
            {
                specName: "Fentes pour cartes",
                values: [
                    "Aucun",
                    "Côté droit",
                    "Côté gauche",
                    "Les deux côtés",
                ]
            },
            {
                specName: "Languettes",
                values: [
                    "Deux poches",
                    "Côté droit intérieur seulement",
                ]
            },
            {
                specName: "Sets",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20"
                ]
            }
            
        ]
    },
    {
        name: "Pochettes standards avec fini UV",
        desc: "Créer des pochettes de présentation qui vont se démarquer avec notre fini glacé UV. En plus, le fini UV fourni une durabilité accrue. La languette standard est de 4”. Une languette optionnelle de 3” est disponible pour les pochettes 9” x 12” et  9” x 14.5”.",
        img: "images/pochettes_corporatives/glossy-presentation-folder.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt Standard UV",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "5.25\" x 10.5\"",
                    "6\" x 9\"",
                    "9\" x 12\" - languette 3\"",
                    "9\" x 12\" - languette 4\"",
                    "9\" x 14.5\" - languette 3\"",
                    "9\" x 14.5\" - languette 4\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "1000",
                    "2500",
                    "5000",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV PG 1 seulement"
                ]
            },
            {
                specName: "Fentes pour cartes",
                values: [
                    "Aucun",
                    "Côté droit",
                    "Côté gauche",
                    "Les deux côtés",
                ]
            },
            {
                specName: "Languettes",
                values: [
                    "Deux poches",
                ]
            },
            {
                specName: "Sets",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20"
                ]
            }
            
        ]
    },
    {
        name: "Pochettes avec laminé mat",
        desc: "Pour des pochettes corporatives qui sont doux au touché, le laminé mat offre un fini velouté. La languette standard est de 4”. Une languette optionnelle de 3” est disponible pour les pochettes 9” x 12” et  9” x 14.5”.",
        img: "images/pochettes_corporatives/custom-presentation-folders.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt Laminé mat",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "9\" x 12\" - languette 3\"",
                    "9\" x 12\" - languette 4\"",
                    "9\" x 14.5\" - languette 3\"",
                    "9\" x 14.5\" - languette 4\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "1000",
                    "2500",
                    "5000",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminé mat 1 côté"
                ]
            },
            {
                specName: "Fentes pour cartes",
                values: [
                    "Aucun",
                    "Côté droit",
                    "Côté gauche",
                    "Les deux côtés",
                ]
            },
            {
                specName: "Languettes",
                values: [
                    "Deux poches",
                    "Côté droit intérieur seulement",
                ]
            },
            {
                specName: "Sets",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20"
                ]
            }
            
        ]
    },
    {
        name: "Pochettes standards avec fini AQ",
        desc: "Pour une apparence semi-glacé, notre pochette Standard AQ est garantie de faire une bonne impression. La languette standard est de 4”. Une languette optionnelle de 3” est disponible pour les pochettes 9” x 12” et  9” x 14.5”.",
        img: "images/pochettes_corporatives/business-presentation-folders.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt Standard AQ",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "5.25\" x 10.5\"",
                    "6\" x 9\"",
                    "9\" x 12\" - languette 3\"",
                    "9\" x 12\" - languette 4\"",
                    "9\" x 14.5\" - languette 3\"",
                    "9\" x 14.5\" - languette 4\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "1000",
                    "2500",
                    "5000",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Gloss AQ"
                ]
            },
            {
                specName: "Fentes pour cartes",
                values: [
                    "Aucun",
                    "Côté droit",
                    "Côté gauche",
                    "Les deux côtés",
                ]
            },
            {
                specName: "Languettes",
                values: [
                    "Deux poches",
                    "Côté droit intérieur seulement",
                ]
            },
            {
                specName: "Sets",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20"
                ]
            }
            
        ]
    },
]