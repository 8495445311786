export const feuillesNcr = [
    {
        name: "Les feuilles NCR",
        desc: "Les feuilles fournissent un moyen pratique de créer plusieurs copies de manuscrits.",
        img: "images/feuilles_ncr/NCR_form.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "20lb imprimé 1 côté"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "1000",
                ]
            },
            {
                specName: "Parties",
                values: [
                    "Trois parties"
                ]
            },
            {
                specName: "Numérotge",
                values: [
                    "Sans numérotge",
                    "Numérotge (rouge)",
                    "Numérotge (noir)",
                ]
            }
            
        ]
    },
]