export const affichesStyrenes = [
    {
        name: "Affiches styrènes",
        desc: "Un produit imprimé sur du PVC durable et léger.",
        img: "images/affiches_styrenes/styrenesign.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "20pt styrène imprimé 1 côté",
                    "20pt styrène imprimé 2 côtés",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "6 x 24",
                    "6 x 32",
                    "6 x 36",
                    "12 x 12",
                    "12 x 16",
                    "12 x 18",
                    "12 x 24",
                    "12 x 36",
                    "12 x 48",
                    "18 x 24",
                    "24 x 24",
                    "24 x 32",
                    "24 x 36",
                    "24 x 48",
                    "25 x 37",
                    "32 x 48",
                    "36 x 36",
                    "36 x 48",
                    "48 x 48",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                    "125",
                    "150",
                    "175",
                    "200",
                ]
            },
            
        ]
    },
]