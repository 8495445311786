export const porteGuarantie = [
    {
        name: "Porte guarantie",
        desc: "",
        img: "images/porte_guarantie/53.jpg",
        specs: [
            
            {
                specName: "Dimensions",
                values: [
                    "6.25\" x 9\" (PV-49)",
                    "6.875\" x 10\" (PV-52)",
                    "6.25\" x 9.5\" (PV-53)",
                    "7.25\" x 10.25\" (PV-58)",
                ]
            },
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                    "Noir",
                    "Gris",
                    "Brige",
                    "Royal",
                    "Rouge",
                    "Bleu marin",
                    "Brun",
                    "Orange",
                    "Jaune",
                    "Vert",
                    "Violet",
                    "Pourpre",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1000",
                    "2500",
                    "5000",
                    "10000",
                ]
            },
            
        ]
    },
    
]