export const vinylePerfore = [
    {
        name: "Vinyle perforé pour fenêtre",
        desc: "Transformer les fenêtres de vos magasins avec des images et publicités accrocheuses. Le matériel crée l'illusion d'un design opaque pour les piétons sans bloquer la lumière entrant dans le magasin.",
        img: "images/vinyle_perfore/window-signage.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle perforé 1 côté imprimé"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "6\" x 24\"",
                    "6\" x 32\"",
                    "6\" x 36\"",
                    "12\" x 12\"",
                    "12\" x 16\"",
                    "12\" x 24\"",
                    "12\" x 72\"",
                    "18\" x 12\"",
                    "18\" x 24\"",
                    "20\" x 30\"",
                    "24\" x 24\"",
                    "24\" x 32\"",
                    "24\" x 36\"",
                    "24\" x 48\"",
                    "24\" x 72\"",
                    "30\" x 84\"",
                    "32\" x 48\"",
                    "36\" x 12\"",
                    "36\" x 36\"",
                    "36\" x 48\"",
                    "36\" x 72\"",
                    "36\" x 84\"",
                    "48\" x 12\"",
                    "48\" x 48\"",
                    "48\" x 72\"",
                    "48\" x 84\"",
                    "48\" x 96\"",
                    "60\" x 12\"",
                    "60\" x 24\"",
                    "60\" x 36\"",
                    "60\" x 48\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            {
                specName: "Sets",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20"
                ]
            }
            
        ]
    },
    
]