export const gobelets = [
    {
        name: "Gobelet en acier inoxydable 20oz personnalisé",
        desc: "Imprimez des gobelets personnalisés pour vos clients sans minimum. Chaque gobelet personnalisé est livré avec un couvercle transparent et une paille en acier inoxydable.",
        img: "images/gobelets/tumbler1.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]