export const cartesDaffaire = [
    {
        name: "Cartes d’affaires – Maximiseur de profits",
        desc: "Ces cartes d’affaires en gros offrent la meilleure valeur considérant les options rentabilité / temps de production.",
        img: "images/cartes_daffaires/cartes_daffaires_maximiseur_de_profits.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Épaisseur",
                values: [
                    "14pt"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Pas d'enduit"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "2-3 jours fériés", "Prochain jour férié"
            //     ]
            // }
            
        ]
    },
    {
        name: "Cartes d’affaires avec fini mat",
        desc: "Nos cartes d’affaires avec fini mat sont nos cartes standards avec le fini le plus populaire. Si vos clients recherchent un fini satiné et élégant, les cartes d’affaires en gros avec fini mat est une bonne option. Ce fini est doux au touché et un bon ajout pour vos œuvres / designs.",
        img: "images/cartes_daffaires/cartes_daffaires_avec_fini_mat.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Épaisseur",
                values: [
                    "14pt", 
                    "16pt"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "2-3 jours fériés", "Prochain jour férié"
            //     ]
            // }

        ]
    },
    {
        name: "Cartes d’affaires avec fini UV (glacé)",
        desc: "Notre fini le plus glacé! Nos cartes d’affaires UV offrent le plus de brillance pour une image éclatante.",
        img: "images/cartes_daffaires/cartes_daffaires_avec_fini_uv_glace.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Épaisseur",
                values: [
                    "14pt", 
                    "16pt"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV PG 1 côté"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "2-3 jours fériés", "Prochain jour férié"
            //     ]
            // }

        ]
    },
    {
        name: "Cartes d’affaires avec un laminé",
        desc: "Nos cartes d’affaires avec laminé offrent plus de durabilité. Le laminé protège la surface de la carte et donne à la carte un touché unique.",
        img: "images/cartes_daffaires/cartes_daffaires_avec_un_lamine.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Épaisseur",
                values: [
                    "18pt laminé glacé", 
                    "18pt laminé mat/satiné"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminé mat 2 côtés",
                    "Laminé mat 1 côté"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "4-5 jours fériés", "Prochain jour férié"
            //     ]
            // }
        ]
    },
    {
        name: "Cartes d’affaires avec fini AQ (semi-glacé)",
        desc: "Nos cartes d’affaires avec fini AQ sont semi-glacés, un intermédiaire parfait entre le glacé et le mat.",
        img: "images/cartes_daffaires/cartes_daffaires_avec_fini_aq_semi_glace.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2",
                    "3.5 x 1.5",
                    "3.5 x 1.75"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Épaisseur",
                values: [
                    "14pt", 
                    "16pt"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Gloss AQ"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "2-3 jours fériés", "Prochain jour férié"
            //     ]
            // }

        ]
    },
    {
        name: "Cartes d'affaires écrivables",
        desc: "Imprimez sur des cartes d’affaires avec surface écrivable car elles sont idéales pour les cartes de rendez-vous et plus encore. Ces cartes d’affaires sans enduits peuvent facilement être écrites, même avec un crayon.",
        img: "images/cartes_daffaires/cartes_daffaires_ecrivables.png",
        specs: [
            
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Types",
                values: [
                    "13pt enviro sans enduits",
                    "13pt lin sans enduits",
                    "14pt + AQ avec surface écrivable",
                    "14pt + UV avec surface écrivable",
                    "18pt avec surface écrivable (C1S)"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "2-4 jours fériés", "Prochain jour férié"
            //     ]
            // }
        ]
    },
    {
        name: "Cartes d'affaires spécialisées",
        desc: "Impressionnez vos clients avec des cartes d’affaires professionnelles & attrayantes, disponibles dans une variété de matériels étonnants et avec une finition allant du film métallique réfléchissant jusqu’au plastique durable.",
        img: "images/cartes_daffaires/cartes_daffaires_specialisees.png",
        specs: [

            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3.5 x 2"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Type de finition",
                values: [
                    "Film métalisé",
                    "Vernis sélectif UV",
                    "Papier Kraft",
                    "Papier perlé",
                    "Durable",
                    "Die cut",
                    "Velouté",
                    "32pt painted edge",
                    "Ultra smooth"
                ]
            },
            {
                specName: "Couleur de la feuille",
                values: [
                    "Feuille métallique dorée sur le devant", "Feuille métallique argentée sur le devant"
                ]
            }
            // ,{
            //     specName: "Temps de production",
            //     values: [
            //         "5 jours fériés"
            //     ]
            // }
            
        ]
    }
]