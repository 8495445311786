export const cartonsMousse = [
    {
        name: "Carton Mousse 4mm",
        desc: "Le carton mousse est une affiche légère et idéale pour l'intérieur.",
        img: "images/cartons_mousse/Foamboard.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Carton mousse 4mm imprimé 1 côté",
                    "Carton mousse 4mm imprimé 2 côtés",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "6 x 24",
                    "6 x 32",
                    "6 x 36",
                    "12 x 12",
                    "12 x 16",
                    "12 x 18",
                    "12 x 24",
                    "12 x 36",
                    "12 x 48",
                    "18 x 24",
                    "24 x 24",
                    "24 x 32",
                    "24 x 36",
                    "24 x 48",
                    "25 x 37",
                    "32 x 48",
                    "36 x 36",
                    "36 x 48",
                    "48 x 48",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                    "125",
                    "150",
                    "175",
                    "200",
                ]
            },
            
        ]
    },
]