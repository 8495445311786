import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Row, Col, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import "../App.css";
import "../styles/NavbarComponent.css"
import "react-bootstrap-submenu/dist/index.css";
// export default function Product(props)

export const NavbarComponent = (props) => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="myBlue" className="custom-nav" style={{ fontWeight: "bolder", marginBottom: 20 }}>
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="http://www.imprintableclothes.com/printzonepromo/start.jsp" target="_blank">MAGASIN DE VÊTEMENTS</Nav.Link>

                            <NavDropdownMenu title="IMPRESSIONS" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/cartesdaffaire">Cartes d'affaire</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cartespostales">Cartes postales</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cartesdevoeux">Cartes de voeux</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cartesperforees">Cartes perforées</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/circulaires">Circulaires</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/depliants">Dépliants</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/accrochesportes">Accroches portes</NavDropdown.Item>
                                <DropdownSubmenu href="" title="Étiquettes">
                                    <NavDropdown.Item as={Link} to="/etiquettesenrouleaux">Étiquettes en rouleaux</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/decals">Decals</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="">À plât</NavDropdown.Item> */}
                                </DropdownSubmenu>
                                <DropdownSubmenu href="" title="Papeterie">
                                    <NavDropdown.Item as={Link} to="/entete">En tête</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/enveloppes">Enveloppes</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/blocsnotes">Blocs notes</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/feuillesncr">Feuilles NCR (sans carbonne)</NavDropdown.Item>
                                </DropdownSubmenu>
                                <NavDropdown.Item as={Link} to="/calendriersmuraux">Calendriers muraux</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/billetsnumerotes">Billets numérotés</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/livrets">Livrets</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/signets">Signets</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/aimants">Aimants</NavDropdown.Item>
                            </NavDropdownMenu>

                            <NavDropdownMenu title="PANCARTES" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/enseignescoroplast">Enseignes coroplast</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cartonsmousse">Cartons mousse</NavDropdown.Item>
                                <DropdownSubmenu href="" title="Affiches">
                                    <NavDropdown.Item as={Link} to="/affichescartonnees">Affiches cartonées</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/affichesstyrenes">Affiches styrene</NavDropdown.Item>
                                </DropdownSubmenu>
                                <NavDropdown.Item as={Link} to="/panneauxdesventes">Panneaux des ventes/PDV</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/panneauxrigides">Panneaux rigides/Sintra</NavDropdown.Item>
                                {/* <NavDropdown.Item href="">Panneaux Sintra</NavDropdown.Item> */}
                                <NavDropdown.Item as={Link} to="/enseignesdetrottoire">Enseignes de troittoir</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/canevas">Canevas</NavDropdown.Item>
                                <DropdownSubmenu href="" title="Bannières">
                                    <NavDropdown.Item as={Link} to="/bannieresretractables">Bannières rétractables</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/bannieresenvinyle">Bannières en vinyle</NavDropdown.Item>
                                </DropdownSubmenu>
                                {/* <NavDropdown.Item href="">Nappes de table</NavDropdown.Item> */}
                                <NavDropdown.Item href="">Enseignes métalliques</NavDropdown.Item>
                                <NavDropdown.Item href="">Enseignes en acrylique</NavDropdown.Item>
                                <NavDropdown.Item href="">Enseignes au sol</NavDropdown.Item>
                            </NavDropdownMenu>

                            <NavDropdownMenu title="GRANDS FORMATS" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/vinyleadhesif">Vinyle adhésif</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/vinyleperfore">Vinyle perforé</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Graphiques muraux personnalisés</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Autocollants muraux</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Enseignes LED</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Signages de réception</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Films pour fenêtre</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Lettrages muraux</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Habillage de voiture</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Lettrage de véhicule</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/grandsformats">Décalque message pare-brise</NavDropdown.Item>

                            </NavDropdownMenu>

                            <NavDropdownMenu title="CONCESSIONNAIRE" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/portepermis">Porte permis en vinyl 35</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/porteguarantie">Porte guarantie en vinyl 53</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/pochettescorporatives">Pochettes corporatives</NavDropdown.Item>
                                <NavDropdown.Item href="">Porte-clés</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/tentespromotionnelles">Tentes promotionnelles</NavDropdown.Item>
                                <DropdownSubmenu href="" title="Drapeaux">
                                    <NavDropdown.Item as={Link} to="/drapeauxdevoiture">Drapeaux de voiture</NavDropdown.Item>
                                    <NavDropdown.Item href="">Beach flag</NavDropdown.Item>

                                </DropdownSubmenu>


                                <NavDropdown.Item as={Link} to="/chouxgeant">Choux géant</NavDropdown.Item>
                                <NavDropdown.Item href="">Lanière de cou</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/tapisdepapier">Tapis de papier</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/ballonspublicitaires">Ballons publicitaires</NavDropdown.Item>
                                <NavDropdown.Item href="">Bannières personnalisées 3'x8'</NavDropdown.Item>
                                <NavDropdown.Item href="">Pochettes de barrure de roue</NavDropdown.Item>
                                {/* <NavDropdown.Item as={Link} to="/bannieredepharebrise">Bannière de phare brise</NavDropdown.Item> */}
                                <NavDropdown.Item as={Link} to="/dancingguys">Dancing guys</NavDropdown.Item>
                                <NavDropdown.Item href="">Tapis personnalisés</NavDropdown.Item>

                            </NavDropdownMenu>

                            <NavDropdownMenu title="ITEMS CADEAU" id="collasible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/tasses">Tasses</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/bouteilles">Bouteilles</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/gobelets">Goblets</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/sousverres">Sous-verres</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/tapisdesouris">Tapis de souris</NavDropdown.Item>
                                {/* <NavDropdown.Item href="">Casse-têtes</NavDropdown.Item> */}
                                <NavDropdown.Item as={Link} to="/panneauxdephotos">Panneaux de photos</NavDropdown.Item>
                                <NavDropdown.Item href="">Sacs réutilisables</NavDropdown.Item>
                                <NavDropdown.Item href="">Désodorisants pour voitures</NavDropdown.Item>
                                <NavDropdown.Item href="">Grattoirs</NavDropdown.Item>
                                <NavDropdown.Item href="">Bouteilles d'eau</NavDropdown.Item>
                            </NavDropdownMenu>

                            <Nav.Link as={Link} to={"/contact"}>CONTACTEZ-NOUS</Nav.Link>

                            <Nav.Link as={Link} to={"/panier"}><i class="fa-solid fa-cart-shopping"></i></Nav.Link>
                            <span class='badge badge-warning' id='lblCartCount'> {props.numProdsInCart} </span>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )
}
