export const aimants = [
    {
        name: "Aimants",
        desc: "Ces aimants personnalisés sont parfaits pour les réfrigérateurs, les bureaux et autres surfaces métalliques.",
        img: "images/aimants/magnet-2.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Aimant 14pt",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "2.5 x 3.5",
                    "4 x 3",
                    "4 x 6",
                    "4 x 7",
                    "4 x 8",
                    "4.25 x 6",
                    "5.5 x 8.5",
                    "5 x 7",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            }
        ]
    },
    {
        name: "Aimants pour voiture",
        desc: "Ces aimants aident vos clients à identifier la marque de commerce sur les véhicules.",
        img: "images/aimants/Car_Magnets.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Aimants pour voitures 30 mil",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "9 x 9",
                    "9 x 12",
                    "9 x 18",
                    "9 x 24",
                    "12 x 12",
                    "12 x 18",
                    "12 x 24",
                    "18 x 18",
                    "18 x 24",
                    "24 x 24",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            {
                specName: "Coins arrondis",
                values: [
                    "Non",
                    "Oui"
                ]
            }
        ]
    },
    {
        name: "Aimants découpés à la forme",
        desc: "Adaptés à une utilisation intérieure et extérieure, ces aimants peuvent être découpés dans n'importe quelle forme dont vous avez besoin.",
        img: "images/aimants/die-cut-magnet-main.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Aimant 30mil",
                    "Aimant 20mil",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "3 x 4",
                    "3.5 x 2",
                    "3.5 x 2.5",
                    "3.5 x 8.5",
                    "4 x 6",
                    "4 x 7",
                    "4 x 8",
                    "4 x 9",
                    "4 x 9",
                    "4 x 11",
                    "4.25 x 5.5",
                    "4.25 x 6",
                    "5.5 x 8.5",
                    "5 x 7",
                    "6 x 8",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            }
        ]
    },
]