import React from 'react'
import Product from "./Product"
import "../styles/ProductsPage.css"
import { useState } from "react"

export const ProductsPage = (props) => {

    const products = props.data.map(product => {
        return <Product 
                    cartItems={props.cartItems} 
                    onAdd={props.onAdd} 
                    onRemove={props.onRemove}
                    name={product.name} 
                    desc={product.desc} 
                    img={product.img} 
                    specs={product.specs} 

                    numProdsInCart={props.numProdsInCart}
                    setNumProdsInCart={props.setNumProdsInCart}
                />
        
    })

    return (

        <div className="products-page-container" >
            {products}
        </div>

    )
}
