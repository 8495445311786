import React from 'react'

import "../styles/Footer.css"

export const Footer = () => {
    return (
        <footer className="footer">

            <div className="footer-container">
                <div className="footer-row">
                    <div className="footer-col">
                        <h4 className="with-animated-underline red">Coordonnées</h4>
                        <ul>
                            <li>PrintZone Promo</li>
                            <li>(514) 883-0076</li>
                            <li>info@printzonepromo.com</li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4 className="with-animated-underline blue">Navigation</h4>
                        <ul>
                            <li><a href="/">Accueil</a></li>
                            <li><a href="/#about-section">À propos</a></li>
                            <li><a href="/#about-section">Nos services</a></li>
                            <li><a href="/#clients-section">Clientèle</a></li>
                            <li><a href="/contact">Nous joindre</a></li>
                        </ul>
                    </div>
                    
                    <div className="footer-col">
                        <h4 className="with-animated-underline yellow">Suivez-nous!</h4>
                        <div className="social-links">
                            <a href="https://www.facebook.com/PrintZonePromo/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/printzonepromo/?hl=en" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                            <a href="https://www.tiktok.com/@printzonepromo" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
                            <a href="https://www.etsy.com/ca/shop/Printzonepromo" target="_blank"><i class="fa-brands fa-etsy"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">&copy;{new Date().getFullYear()} PrintZone Promo | Tous droits réservés.</div>

        </footer>
    )
}
