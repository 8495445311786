export const blocsNotes = [
    {
        name: "Blocs notes (sans enduits)",
        desc: "Les enveloppes sans enduits sont offertes en trois variétés pour répondre aux besoins d'envoi.",
        img: "images/blocs_notes/blocs_notes.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "60lb sans enduits"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "4 x 6",
                    "4.25 x 5.5",
                    "5.5 x 8.5",
                    "8.5 x 11",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                ]
            },
            {
                specName: "Page",
                values: [
                    "25 pages",
                    "50 pages"
                ]
            }
            
        ]
    },
]