export const billetsNumerotes = [
    {
        name: "Billets numérotés",
        desc: "Les billets numérotés sont imprimés avec des données variables. Ils sont parfait pour des concerts, des concours, et pour des évènements.",
        img: "images/billets_numerotes/billets.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt C2S",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "2 x 4",
                    "2 x 5.5",
                    "3.5 x 6",
                    "8.5 x 3.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            },
            {
                specName: "Nombre de variables",
                values: [
                    "1-2 variables"
                ]
            }
        ]
    },
]