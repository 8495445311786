export const tasses = [
    {
        name: "Tasse en céramique 11oz",
        desc: "Imprimez des tasses en céramique de 11oz qui sont idéales pour un usage quotidien. Disponible dans une variété de couleurs.",
        img: "images/tasses/11oz.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                    "Rouge",
                    "Jaune",
                    "Orange",
                    "Noir",
                    "Rose",
                    "Bleu",
                    "Vert"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
    {
        name: "Tasse en céramique 15oz",
        desc: "Imprimez des tasses en céramique de 11oz qui sont idéales pour un usage quotidien. Disponible dans une variété de couleurs.",
        img: "images/tasses/15oz.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                    "Rouge",
                    "Jaune",
                    "Noir",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
    {
        name: "Tasse en céramique 11oz",
        desc: "Imprimez des tasses en céramique 15 oz qui sont idéales pour un usage quotidien. Disponible dans une variété de couleurs.",
        img: "images/tasses/11oz.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                    "Rouge",
                    "Jaune",
                    "Orange",
                    "Noir",
                    "Rose",
                    "Bleu",
                    "Vert"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
    {
        name: "Tasse 10oz en acier inoxydable",
        desc: "Ces tasses en acier inoxydable imprimées sur mesure sont faciles à nettoyer et sont livrées avec un couvercle transparent.",
        img: "images/tasses/10oz.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
    {
        name: "Chope à bière transparente 18 oz",
        desc: "Ces chopes à bière transparentes permettent aux utilisateurs de voir le contenu à l'intérieur.",
        img: "images/tasses/clear_beer_mug_cat.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
    {
        name: "Chope de bière givrée de 16 oz",
        desc: "Les chopes à bière givrées ont un aspect plutôt mat et frais.",
        img: "images/tasses/frosted_beer_mug_cat.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]