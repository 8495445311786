import React, { useState } from "react";
import "../../styles/Cart.css"
import CartEntry from '../CartEntry'
import { QuoteForm } from "../QuoteForm";


export const Cart = (props) => {

  const { cartItems } = props

  const [isOpen, setIsOpen] = useState(false);

  return (

    <div className="cart-container">

      <div className="cart-container-title">
        {
          props.numProdsInCart == 0 ?
          <div>
            Vous n'avez aucun produit dans votre panier. Ajoutez des produits pour demander un devis.
          </div>
          :
          <div>
            Voici les produits que vous avez choisis pour votre demande de devis.
          </div>
        }
      </div>

      <div className="products-list">
        {cartItems.map(item => {
          return <CartEntry
            name={item.name}
            productStates={item.states}
            onRemove={props.onRemove}
            product={item}

            numProdsInCart={props.numProdsInCart}
            setNumProdsInCart={props.setNumProdsInCart}
          />
        })}
      </div>

      {/* <QuoteModal
        cartItems={props.cartItems}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
      </QuoteModal> */}

      <QuoteForm
        cartItems={props.cartItems}
      >
      </QuoteForm>

    </div>
  )
}
