import React, { useState } from "react";
import "../styles/Product.css"
import { Link } from "react-router-dom";
import styled from 'styled-components';

import { Modal } from "./Modal";
import { update } from "react-spring";

export default function Product(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([])

    // NEW NEW NEW NEW NEW NEW 
    const [totalProductState, setTotalProductState] = useState({
        name: props.name,
        states: [
            {
                name: "Côtés imprimés",
                value: ""
            },
            {
                name: "Taille",
                value: ""
            },
            {
                name: "Quantité",
                value: ""
            },
            {
                name: "Coins arrondis",
                value: ""
            },
            {
                name: "Épaisseur",
                value: ""
            },
            {
                name: "Finition",
                value: ""
            },
            // {
            //     name: "Temps de production",
            //     value: ""
            // },
            {
                name: "Enduit",
                value: ""
            },
            {
                name: "Couleur de la feuille",
                value: ""
            },
            {
                name: "Type",
                value: ""
            },
            {
                name: "Matériel",
                value: ""
            },
            {
                name: "Laminage",
                value: ""
            },
            {
                name: "Type de pliage",
                value: ""
            },
            {
                name: "Reliure",
                value: ""
            },
            {
                name: "Page",
                value: ""
            },
            {
                name: "Nombre de variables",
                value: ""
            },
            {
                name: "Couvertures",
                value: ""
            },
            {
                name: "Dimensions",
                value: ""
            },
            {
                name: "Couleur de l'encre",
                value: ""
            },
            {
                name: "Forme",
                value: ""
            },
            {
                name: "Fini",
                value: ""
            }, 
            {
                name: "Perforation",
                value: ""
            },
            {
                name: "Surface/Couverture",
                value: ""
            },
            {
                name: "Parties",
                value: ""
            },
            {
                name: "Numérotage",
                value: ""
            }, 
            {
                name: "Oeillets",
                value: ""
            },
            {
                name: "Compatible avec support en H",
                value: ""
            },
            {
                name: "Besoin de support en H",
                value: ""
            },
            {
                name: "Impression",
                value: ""
            },
            {
                name: "Pellicule blanche de 1 pouce",
                value: ""
            },
            {
                name: "Couleur",
                value: ""
            }, 
            {
                name: "Inclure support en métal",
                value: ""
            },
            {
                name: "Ourlets",
                value: ""
            },
            {
                name: "Volume",
                value: ""
            },
            {
                name: "Inclure support à ballon",
                value: ""
            },
            {
                name: "Hauteur",
                value: ""
            },
            {
                name: "Inclure blower HD haute performance",
                value: ""
            }, 
            {
                name: "Fentes pour cartes",
                value: ""
            },
            {
                name: "Languettes",
                value: ""
            },
            {
                name: "Sets",
                value: ""
            }



        ]
    })

    const getStateFromName = (name) => {
        const arr = totalProductState.states.filter(state => {
            return state.name === name
        })
        
        if(arr.length > 0) return arr[0]
    }

    const getStateValue = (name) => {

        let val = ""
        const arr = totalProductState.states.map(state => {
            if(state.name === name) {
                val = state.value
            } 
        })

        return val
    }

    const updateProductStateAfterChange = (specName, value) => {
        
        const clone = structuredClone(totalProductState)

        clone.states.map(state => {
            if(state.name === specName) {
                state.value = value
            }
        })

        setTotalProductState(clone)
    }

    return (


        <div className="product-container">
            {/* <img className="product-img" src={props.img}></img> */}
            <img className="product-img" src={props.img}></img>

            <h1 className="product-title">{props.name}</h1>

            <div className="product-desc">{props.desc}</div>
            <button onClick={() => setIsOpen(true)} className="add-button">Demander un devis</button>

            <Modal
                cartItems={props.cartItems}
                onAdd={props.onAdd}
                onRemove={props.onRemove}

                open={isOpen}
                onClose={() => setIsOpen(false)}
                name={props.name}
                specs={props.specs}

                totalProductState={totalProductState}
                setTotalProductState={setTotalProductState}

                numProdsInCart={props.numProdsInCart}
                setNumProdsInCart={props.setNumProdsInCart}

                getStateFromName={getStateFromName}
                updateProductStateAfterChange={updateProductStateAfterChange}
                getStateValue={getStateValue}
            >

            </Modal>

        </div>
    )
}