export const cartesDeVoeux = [
    {
        name: "Cartes de voeux avec fini UV (glacé)",
        desc: "La carte de voeux avec fini UV a une apparence brillante qui fait éclater les images et les couleurs.",
        img: "images/cartes_de_voeux/uv.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt + fini UV (glacé)"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV"
                ]
            }
        ]
    },
    {
        name: "Cartes d’invitation avec fini AQ (semi-glacé)",
        desc: "La carte de voeux avec fini AQ a une apparence semi-glacé et une bonne luisance.",
        img: "images/cartes_de_voeux/fini_aq.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt + fini AQ"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "AQ glacé"
                ]
            }
        ]
    },
    {
        name: "Cartes de voeux écrivables",
        desc: "Imprimez des cartes de vœux personnalisées avec l’option écrivable afin que vos clients puissent les personnaliser avec un stylo ou crayon. Ils sont parfaits pour les cartes d'anniversaire, les cartes de vœux et plus encore.",
        img: "images/cartes_de_voeux/ecrivables.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Enviro 13pt sans enduit",
                    "Écrivable 14pt + fini AQ (C1S)",
                    "Écrivable 14pt + fini UV (C1S)"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
        ]
    },
    {
        name: "Cartes de voeux avec feuilles métalliques",
        desc: "Imprimez vos cartes de vœux laminées sur film doré or argenté pour un effet festif et majestueux. Avec l’impression en gros de cartes métalliques, vous pourrez offrir des cartes de vœux avec films métalliques alors que vos concurrents ne le pourront pas.",
        img: "images/cartes_de_voeux/metallique.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Couleur de la feuille",
                values: [
                    "Feuille métallique dorée sur le devant",
                    "Feuille métallique argentée sur le devant"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminage mat (2 côtés)"
                ]
            }
        ]
    },
    {
        name: "Cartes de voeux avec vernis sélectif UV",
        desc: "Les cartes de vœux avec vernis sélectif UV sont enfin arrivées! Donnez un bel aspect et une bonne sensation brillante à vos cartes UV et laissez-les transformer les cartes de vœux personnalisées de vos clients avec leur revêtement transparent de qualité.",
        img: "images/cartes_de_voeux/vernis_uv.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminage mat (2 côtés)"
                ]
            }
        ]
    },
    {
        name: "Cartes de voeux Kraft",
        desc: "Les cartes de vœux Kraft sont imprimées sur du papier cartonné Kraft de haute qualité, recyclable à 100%. L'impression sur du papier Kraft peut conférer un look unique à la carte de vœux la plus générique.",
        img: "images/cartes_de_voeux/kraft.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "18pt"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            }
        ]
    },
    {
        name: "Cartes de voeux perlées",
        desc: "Les cartes de voeux perlées se distinguent des autres cartes de vœux par le magnifique stock de papier brillant. Ils aident tout fournisseur d’impression à faire passer leur sélection d’impression de carte de vœux personnalisée au niveau supérieur.",
        img: "images/cartes_de_voeux/perlees.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "10 x 7"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            }
        ]
    }

]