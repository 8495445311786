export const cartesPostales = [
    {
        name: "Cartes postales avec fini mat",
        desc: "Les cartes postales avec fini mat offrent un fini doux et élégant. Cette option pour les cartes postales personnalisées est la plus abordable et efficace sur le plan économique et pratique pour vos solutions en marketing.",
        img: "images/cartes_postales/fini_mat.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "10pt avec fini mat",
                    "14pt avec fini mat",
                    "16pt avec fini mat"
                ]
            }
            ,
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            }
        ]
    },
    {
        name: "Cartes postales UV (avec fini brillant - glacé)",
        desc: "Pour une finition exceptionnelle et glacé, choisissez nos Cartes postales UV. Les couleurs sont saturées et les images apparaissent vives avec brillantes.",
        img: "images/cartes_postales/uv.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt UV",
                    "16pt UV"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV PG 1"
                ]
            }
        ]
    },
    {
        name: "Cartes postales laminées",
        desc: "Vous avez besoin un peu plus de durabilité? Notre laminé glacé et mat/satiné offrent une couche supplémentaire de protection pour vos cartes postales en gros.",
        img: "images/cartes_postales/laminees.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "18pt Laminé glacé",
                    "18pt Laminé Mat / Satiné"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminé glacé 2 côtés",
                    "Laminé glacé PG 1 seulement"
                ]
            }
        ]
    },
    {
        name: "Cartes postales avec fini AQ",
        desc: "Notre fini AQ donne un look semi-glacé et une brillance parfaite pour compléter toute conception.",
        img: "images/cartes_postales/fini_aq.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "10pt AQ",
                    "14pt AQ",
                    "16pt AQ"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "AQ glacé"
                ]
            }
        ]
    },
    {
        name: "Cartes postales écrivables sans enduits",
        desc: "Les cartes postales personnalisées sans enduits permettent à vos clients d'écrire un message personnalisé avec crayon ou stylo. Ces cartes ont au moins un côté non revêtu. Imprimez-les en gros maintenant",
        img: "images/cartes_postales/ecrivables.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "13pt Enviro sans enduits",
                    "13pt Lin sans enduits"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
        ]
    },
    {
        name: "Cartes postales écrivables avec enduits",
        desc: "Les cartes postales avec enduits + surface écrivable sont aussi un excellent choix.",
        img: "images/cartes_postales/ecrivables_enduit.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt + AQ avec surface écrivable",
                    "14pt + UV avec surface écrivable",
                    "18pt Laminé mat + SPOT UV (1 côté)"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
        ]
    }
    
]