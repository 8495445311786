export const enseignesCoroplast = [
    {
        name: "Enseignes Coroplast Quick Ship",
        desc: "“Quick Sign” est une impression rapide des panneaux.",
        img: "images/enseignes_coroplast/coroplast-quickship.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "4mm Coroplast imprimé 1 côté",
                    "4mm Coroplast imprimé 2 côtés",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "18 x 24",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                    "125",
                    "150",
                    "175",
                    "200",
                ]
            },
            {
                specName: "Oeillets",
                values: [
                    "Sans passe-fil"
                ]
            },
            {
                specName: "Compatible avec support en H",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Besoin de support en H",
                values: [
                    "Non",
                ]
            },
            
        ]
    },
    {
        name: "Enseignes Coroplast 4/6/8/10mm",
        desc: "Les enseignes en coroplast sont durables et une bonne option pour des affiches extérieurs. Ces enseignes sont typiquement utilisées comme des enseignes de pelouse.",
        img: "images/enseignes_coroplast/lawn-sign-printing.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "4mm Coroplast",
                    "6mm Coroplast",
                    "8mm Coroplast",
                    "10mm Coroplast",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "6 x 24",
                    "6 x 32",
                    "6 x 36",
                    "12 x 12",
                    "12 x 16",
                    "12 x 18",
                    "12 x 24",
                    "12 x 36",
                    "12 x 48",
                    "18 x 24",
                    "24 x 24",
                    "24 x 32",
                    "24 x 36",
                    "24 x 48",
                    "25 x 37",
                    "32 x 48",
                    "36 x 36",
                    "36 x 48",
                    "48 x 48",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                    "125",
                    "150",
                    "175",
                    "200",
                    "300",
                    "400",
                    "500",
                ]
            },
            {
                specName: "Oeillets",
                values: [
                    "Sans passe-fil",
                    "2 coins supérieurs",
                    "4 coins",
                    "Immobilier, haut",
                    "Immobilier, haut et bas"
                ]
            },
            {
                specName: "Compatible avec support en H",
                values: [
                    "Non",
                    "Oui"
                ]
            },
            {
                specName: "Besoin de support en H",
                values: [
                    "Non",
                ]
            },
            
        ]
    },
    
]