import React from 'react'
import "../../styles/Contact.css"

export const Contact = () => {
  return (
    <div className="contact-container">
      
      <form className="contact-form" action="https://formsubmit.co/printzonepromo@gmail.com" method="POST">
        <h3>Pour toutes vos questions, remplissez le formulaire suivant.</h3>
        <input type="text" name="name" id="name" placeholder="Votre nom" required />
        <input type="email" name="email" id="email" placeholder="Votre email" required />
        <textarea name="message" id="message" rows="4" placeholder="Votre message"></textarea>
        <button className="submit-button" type="submit">Envoyer</button>
      </form>

    </div>
  )
}
