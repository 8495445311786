export const tapisDePapier = [
    {
        name: "Tapis de papier",
        desc: "",
        img: "",
        specs: [
            
            {
                specName: "Matériel",
                values: [
                    "100LB avec fini mat",
                ]
            },
            {
                specName: "Dimensions",
                values: [
                    "12.5\" x 19\"",
                    "18\" x 24\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000",
                    "10000",
                    "15000",
                    "20000",
                    "25000",
                    "30000",
                    "35000",
                    "40000",
                    "45000",
                    "50000",
                ]
            },
            
        ]
    },
    
]