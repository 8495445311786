export const circulaires = [

    {
        name: "Circulaires - Gloss text",
        desc: "Lorsque vous voulez sur le produit une touche de brillance, essayez notre papier Gloss text de haute qualité. Ce papier semi-brillant offre une excellente option pour une impression ayant des couleurs vibrantes.",
        img: "images/circulaires/gloss.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb Gloss Text"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
        ]
    },
    {
        name: "Circulaires avec fini mat",
        desc: "Pour une apparence plus lustré, essayez nos circulaires avec fini mat. Notre fini mat est plus élégant et donne au toucher une sensation douce et lisse.",
        img: "images/circulaires/mat.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb fini mat"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
        ]
    },
    {
        name: "Circulaires avec fini UV (glacé)",
        desc: "Est-ce que la conception du circulaire incorpore des images et visuels vives? Commandez le circulaire avec l’enduit UV! Ce fini brillant est sûr d'attirer l'attention à votre message de marketing.",
        img: "images/circulaires/uv.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb UV"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV"
                ]
            }
        ]
    },
    {
        name: "Circulaires sans enduit (100% recyclé)",
        desc: "C’est facile d’être écologique avec notre papier sans enduit Enviro. Ce papier sans enduit est fait de papier qui est 100% recyclé et est une excellente alternative de marketing écologique.",
        img: "images/circulaires/recycle.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "80lb Enviro sans enduit (30% recyclé)"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    ""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            }
            
        ]
    },
    {
        name: "Circulaires sans enduit en Lin",
        desc: "Nos circulaires en lin sans enduit ont une texture naturelle qui produit un toucher unique. Ce papier sans enduit créer des matériels de marketing luxueux avec une qualité qui se distingue au touché.",
        img: "images/circulaires/lin.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "70lb en Lin (sans enduit)"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 5.5",
                    "8.5 x 11",
                    "8.5 x 14",
                    "11 x 17"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000"
                ]
            }
            
        ]
    }

]