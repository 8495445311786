export const panneauxDePhotos = [
    {
        name: "Panneau photo HD, ilumipix™ Aluminium",
        desc: "Affichez les photos de vos clients sous forme de panneaux photo personnalisés, éclatants et durables, parfaits pour l'art mural et la décoration intérieure personnalisée. Chaque panneau mesure 0,045\" d'épaisseur.",
        img: "images/panneaux_de_photos/8x10-hd-photo-panel.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8\" x 10\"",
                    "10\" x 8\"",
                    "5\" x 7\"",
                    "7\" x 5\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]