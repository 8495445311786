export const enseignesDeTroittoire = [
    {
        name: "Enseigne de trottoire ",
        desc: "Ces enseignes sont portables et simples comme outils de marketing. Ils captent l'attention des passagers qui circulent vos rues et trottoires.",
        img: "images/enseignes_de_trottoire/a-frame-one.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "4mm Coroplast imprimé 1 côté",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "18 x 24",
                    "36 x 24",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            {
                specName: "Impression",
                values: [
                    "Même image des deux côtés",
                    "Images différentes des deux côtés",
                ]
            },
            
        ]
    },
]