export const depliants = [
    {
        name: "Dépliants sur Gloss Text",
        desc: "Ces dépliants sont imprimés sur du papier Gloss Text de haute qualité et donnent un look semi-glacé. Ceci est notre type de papier le plus populaire pour imprimer des dépliants.",
        img: "images/depliants/gloss.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb Gloss Text"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11",
                    "8.5 x 14",
                    "9 x 12",
                    "11 x 17",
                    "11 x 25.5",
                    "17 x 22"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Type de pliage",
                values: [
                    "Half Fold",
                    "Roll Fold",
                    "Z Fold",
                    "Tri Fold",
                    "Double Parallel",
                    "8 Page Fold",
                    "4 Panel Accordion Fold",
                    "Double Gate",
                    "Gate",
                    "Score and Half",
                    "Score and Roll",
                    "Score and Z",
                    "Score and Double Parallel",
                    "Score and Tri",
                ]
            }
        ]
    },
    {
        name: "Dépliants avec fini mat",
        desc: "Vous recherchez des dépliants en gros avec une surface lisse au touché. Notre fini mat pour les dépliants offre une appareance neutre et donne une bonne surface pour le texte.",
        img: "images/depliants/mat.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb avec fini mat"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11",
                    "8.5 x 14",
                    "9 x 12",
                    "11 x 17",
                    "11 x 25.5",
                    "17 x 22"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            },
            {
                specName: "Type de pliage",
                values: [
                    "Half Fold",
                    "Roll Fold",
                    "Z Fold",
                    "Tri Fold",
                    "Double Parallel",
                    "8 Page Fold",
                    "4 Panel Accordion Fold",
                    "Double Gate",
                    "Gate",
                    "Score and Half",
                    "Score and Roll",
                    "Score and Z",
                    "Score and Double Parallel",
                    "Score and Tri",
                ]
            }
        ]
    },
    {
        name: "Dépliants avec fini UV (glacé)",
        desc: "Le fini UV sur les dépliants fournit un vernis de protection avec une surface très glacée. Ce fini ajoute de l’éclat aux détails des images donc choisissez le fini UV pour les dépliants avec beaucoup d’images.",
        img: "images/depliants/uv.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "100lb UV"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11",
                    "8.5 x 14",
                    "9 x 12",
                    "11 x 17",
                    "11 x 25.5",
                    "17 x 22"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV"
                ]
            },
            {
                specName: "Type de pliage",
                values: [
                    "Half Fold",
                    "Roll Fold",
                    "Z Fold",
                    "Tri Fold",
                    "Double Parallel",
                    "8 Page Fold",
                    "4 Panel Accordion Fold",
                    "Double Gate",
                    "Gate",
                    "Score and Half",
                    "Score and Roll",
                    "Score and Z",
                    "Score and Double Parallel",
                    "Score and Tri",
                ]
            }
        ]
    },
    {
        name: "Dépliants avec papier recyclé 100%",
        desc: "Nos dépliants avec papier 100% recyclé sont de très bons produits pour les clients qui sont respectueux de l’environnement. Ce papier est idéal pour l’environnement.",
        img: "images/depliants/recycle.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "80lb Enviro recyclé"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11",
                    "8.5 x 14",
                    "9 x 12",
                    "11 x 17",
                    "11 x 25.5",
                    "17 x 22"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            },
            {
                specName: "Type de pliage",
                values: [
                    "Half Fold",
                    "Roll Fold",
                    "Z Fold",
                    "Tri Fold",
                    "Double Parallel",
                    "8 Page Fold",
                    "4 Panel Accordion Fold",
                    "Double Gate",
                    "Gate"
                ]
            }
        ]
    },
]