export const decals = [
    {
        name: "Décalques permanents coupés à la forme",
        desc: "Nos décalques permanents sont parfaits pour l'image de marque ou pour afficher un message durable.",
        img: "images/decals/pd-main-image.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle blanc permanent 4mil"
                ]
            },
            {
                specName: "Forme",
                values: [
                    "Cercle",
                    "Oval",
                    "Carré",
                    "Rectangle",
                    "Personnalisé",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "À spécifier"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            },
            {
                specName: "Fini",
                values: [
                    "Coupé à la forme"
                ]
            }
        ]
    },
    {
        name: "Décalques amovibles coupées à la forme",
        desc: "Ces décalques temporaires aident vos clients à communiquer ou à décorer sans engagement.",
        img: "images/decals/rd-main-image.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle blanc amovible 4mil"
                ]
            },
            {
                specName: "Forme",
                values: [
                    "Cercle",
                    "Oval",
                    "Carré",
                    "Rectangle",
                    "Personnalisé",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "À spécifier"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            },
            {
                specName: "Fini",
                values: [
                    "Coupé à la forme"
                ]
            }
        ]
    },
]