import React from 'react'

import "../../styles/GrandsFormats.css"

export const GrandsFormats = () => {
  

  return (
    <div className="grands-formats-container">
        Pour tous vos projets grand format, veuillez nous contacter à printzonepromo@gmail.com.
    </div>
  )
}
