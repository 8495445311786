export const enTete = [
    {
        name: "En tête",
        desc: "L’en tête corporatif imprimé sur un papier de qualité 60lb sans enduits donne un look professionnel aux correspondances d'affaires.",
        img: "images/en_tete/en_tete.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "60lb sans enduits"
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                    "5000"
                ]
            },
            
        ]
    },
]