export const drapeauxDeVoiture = [
    {
        name: "Drapeaux de voiture clip-on",
        desc: "",
        img: "images/drapeaux_de_voiture/drapeaux_de_voiture.jpg",
        specs: [
            
            {
                specName: "Dimensions",
                values: [
                    "12\" x 16\"",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1-12",
                    "50",
                    "100",
                    "250",
                ]
            },
            
        ]
    },
    
]