import { Home } from "../components/pages/Home";
import { Contact } from "../components/pages/Contact";
import { Cart } from "../components/pages/Cart";
import { ProductsPage } from "../components/ProductsPage"
import { GrandsFormats } from "../components/pages/GrandsFormats";

// products pages data (products contained in each category)
import { cartesDaffaire } from "../data/cartesDaffaireData";
import { cartesPostales } from "../data/cartesPostalesData";
import { cartesDeVoeux } from "../data/cartesDeVoeuxData";
import { cartesPerforees } from "../data/cartesPerforeesData";
import { circulaires } from "../data/circulairesData";
import { depliants } from "../data/depliantsData";
import { accrochesPortes } from "../data/accrochesPortesData";
import { calendriersMuraux } from "../data/calendriersMurauxData";
import { billetsNumerotes } from "../data/billetsNumerotesData";
import { livrets } from "../data/livretsData";
import { signets } from "../data/signetsData";
import { aimants } from "../data/aimantsData";
import { decals } from "../data/decalsData";
import { etiquettesEnRouleau } from "../data/etiquettesEnRouleauData";
import { enTete } from "../data/enTeteData";
import { enveloppes } from "../data/enveloppesData";
import { blocsNotes } from "../data/blocsNotesData";
import { feuillesNcr } from "../data/feuillesNcrData";
import { enseignesCoroplast } from "../data/enseignesCoroplastData";
import { cartonsMousse } from "../data/cartonsMousseData";
import { affichesCartonnees } from "../data/affichesCartonnees";
import { affichesStyrenes } from "../data/affichesStyrenesData";
import { panneauxDesVentes } from "../data/panneauxDesVentesData";
import { panneauxRigides } from "../data/panneauxRigidesData";
import { enseignesDeTroittoire } from "../data/enseignesDeTrottoireData";
import { canevas } from "../data/canevasData";
import { bannieresRetractables } from "../data/bannieresRetractablesData";
import { bannieresEnVinyle } from "../data/bannieresEnVinyleData";
import { tasses } from "../data/tassesData";
import { bouteilles } from "../data/bouteillesData";
import { gobelets } from "../data/gobeletData";
import { sousVerres } from "../data/sousVerresData";
import { tapisDeSouris } from "../data/tapisDeSourisData";
import { panneauxDePhotos } from "../data/panneauxPhotosData";
import { pochettesCorporatives } from "../data/pochettesCorporativesData";
import { tentesPromotionnelles } from "../data/tentesPromotionnellesData";
import { ballonsPublicitaires } from "../data/ballonsPublicitairesData";
import { chouxGeant } from "../data/chouxGeantData";
import { drapeauxDeVoiture } from "../data/drapeauxDeVoitureData";
import { dancingGuys } from "../data/dancingGuysData";
import { tapisDePapier } from "../data/tapisDePapierData";
import { banniereDePhareBrise } from "../data/banniereDePhareBriseData";
import { porteGuarantie } from "../data/porteGuarantie";
import { portePermis } from "../data/portePermisData";
import { vinylePerfore } from "../data/vinylePerforeData";
import { vinyleAdhesif } from "../data/vinyleAdhesifData";


const routes = [
    // {path: "/", exact: true, name: "Home", component: <Home/>},
    // {path: "/contact", exact: true, name: "Contact", component: <Contact/>},
    // {path: "/panier", exact: true, name: "Cart", component: <Cart />},
    // {path: "/cartesdaffaire", exact: true, name: "CartesDaffaire", component: <ProductsPage data={cartesDaffaire}/> },

    {path: "/", name: "Home", component: Home},
    {path: "/contact", name: "Contact", component: Contact},
    {path: "/panier", name: "Cart", component: Cart},
    {path: "/grandsformats", name:"GrandsFormats", component: GrandsFormats},

    {path: "/cartesdaffaire", name: "CartesDaffaire", component: ProductsPage, data: cartesDaffaire },
    {path: "/cartespostales", name: "CartesPostales", component: ProductsPage, data: cartesPostales},
    {path: "/cartesdevoeux", name:"CartesDeVoeux", component: ProductsPage, data: cartesDeVoeux},
    {path: "/cartesperforees", name:"CartesPerforees", component: ProductsPage, data: cartesPerforees},
    {path: "/circulaires", name:"Circulaires", component: ProductsPage, data: circulaires},
    {path: "/depliants", name:"Depliants", component: ProductsPage, data: depliants},
    {path: "/accrochesportes", name:"AccrochesPortes", component: ProductsPage, data: accrochesPortes},
    {path: "/calendriersmuraux", name:"CalendriersMuraux", component: ProductsPage, data: calendriersMuraux},
    {path: "/billetsnumerotes", name:"BilletsNumerotes", component: ProductsPage, data: billetsNumerotes},
    {path: "/livrets", name:"Livrets", component: ProductsPage, data: livrets},
    {path: "/signets", name:"Signets", component: ProductsPage, data: signets},
    {path: "/aimants", name:"Aimants", component: ProductsPage, data: aimants},
    {path: "/decals", name:"Decals", component: ProductsPage, data: decals},
    {path: "/etiquettesenrouleaux", name:"EtiquettesEnRouleaux", component: ProductsPage, data: etiquettesEnRouleau},
    {path: "/entete", name:"EnTete", component: ProductsPage, data: enTete},
    {path: "/enveloppes", name:"Enveloppes", component: ProductsPage, data: enveloppes},
    {path: "/blocsnotes", name:"BlocsNotes", component: ProductsPage, data: blocsNotes},
    {path: "/feuillesncr", name:"FeuillesNcr", component: ProductsPage, data: feuillesNcr},
    {path: "/enseignescoroplast", name:"EnseignesCoroplast", component: ProductsPage, data: enseignesCoroplast},
    {path: "/cartonsmousse", name:"CartonsMousse", component: ProductsPage, data: cartonsMousse},
    {path: "/affichescartonnees", name:"AffichesCartonnees", component: ProductsPage, data: affichesCartonnees},
    {path: "/affichesstyrenes", name:"AffichesStyrenes", component: ProductsPage, data: affichesStyrenes},
    {path: "/panneauxdesventes", name:"PanneauxDesVentes", component: ProductsPage, data: panneauxDesVentes},
    {path: "/panneauxrigides", name:"PanneauxRigides", component: ProductsPage, data: panneauxRigides},
    {path: "/enseignesdetrottoire", name:"EnseignesDeTrottoire", component: ProductsPage, data: enseignesDeTroittoire},
    {path: "/canevas", name:"Canevas", component: ProductsPage, data: canevas},
    {path: "/bannieresretractables", name:"BannieresRetractables", component: ProductsPage, data: bannieresRetractables},
    {path: "/bannieresenvinyle", name:"BannieresEnVinyle", component: ProductsPage, data: bannieresEnVinyle},
    {path: "/tasses", name:"Tasses", component: ProductsPage, data: tasses},
    {path: "/bouteilles", name:"Bouteilles", component: ProductsPage, data: bouteilles},
    {path: "/gobelets", name:"Gobelets", component: ProductsPage, data: gobelets},
    {path: "/sousverres", name:"SousVerres", component: ProductsPage, data: sousVerres},
    {path: "/tapisdesouris", name:"TapisDeSouris", component: ProductsPage, data: tapisDeSouris},
    {path: "/panneauxdephotos", name:"PanneauxDePhotos", component: ProductsPage, data: panneauxDePhotos},
    {path: "/pochettescorporatives", name:"PochettesCorporatives", component: ProductsPage, data: pochettesCorporatives},
    {path: "/tentespromotionnelles", name:"TentesPromotionnelles", component: ProductsPage, data: tentesPromotionnelles},
    {path: "/ballonspublicitaires", name:"BallonsPublicitaires", component: ProductsPage, data: ballonsPublicitaires},
    {path: "/chouxgeant", name:"ChouxGeant", component: ProductsPage, data: chouxGeant},
    {path: "/drapeauxdevoiture", name:"DrapeauxDeVoiture", component: ProductsPage, data: drapeauxDeVoiture},
    {path: "/dancingguys", name:"DancingGuys", component: ProductsPage, data: dancingGuys},
    {path: "/tapisdepapier", name:"TapisDePapier", component: ProductsPage, data: tapisDePapier},
    {path: "/bannieredepharebrise", name:"BanniereDePhareBrise", component: ProductsPage, data: banniereDePhareBrise},
    {path: "/porteguarantie", name:"PorteGuarantie", component: ProductsPage, data: porteGuarantie},
    {path: "/portepermis", name:"PortePermis", component: ProductsPage, data: portePermis},
    {path: "/vinyleperfore", name:"VinylePerfore", component: ProductsPage, data: vinylePerfore},
    {path: "/vinyleadhesif", name:"VinyleAdhesif", component: ProductsPage, data: vinyleAdhesif},
    
    

]

export default routes;

