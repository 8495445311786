export const etiquettesEnRouleau = [
    {
        name: "Étiquettes BOPP (De Luxe)",
        desc: "Nos étiquettes de luxe BOPP feront le travail pour vous. Fabriqués à partir d'un film plastique, ils résistent à l'eau et aux rayons UV et permettent à vos étiquettes d'avoir un impact coloré et brillant.",
        img: "images/etiquettes_en_rouleau/bopp-label-new.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "White Gloss BOPP Permanent",
                    "Silver Brushed BOPP Permanent",
                    "Clear BOPP",
                    "White Gloss BOPP Freezer",
                ]
            },
            {
                specName: "Forme",
                values: [
                    "Cercle",
                    "Oval",
                    "Carré",
                    "Rectangle",
                    "Personnalisé",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "À spécifier"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Fini",
                values: [
                    "Glacé UV",
                    "Laminage mat",
                    "Laminage glacé",
                    "Mat UV",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            },
            {
                specName: "Perforation",
                values: [
                    "Non",
                    "Oui"
                ]
            }
        ]
    },
    {
        name: "Étiquettes Poly (Durable)",
        desc: "Les étiquettes en rouleaux de Poly (abréviation pour le polyester) sont nos étiquettes les plus durables et sont meilleures pour un éventail de vos besoins de vente.",
        img: "images/etiquettes_en_rouleau/poly-label-new.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "White Poly",
                    "Silver Poly",
                ]
            },
            {
                specName: "Forme",
                values: [
                    "Cercle",
                    "Oval",
                    "Carré",
                    "Rectangle",
                    "Personnalisé",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "À spécifier"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Fini",
                values: [
                    "Glacé UV",
                    "Laminage mat",
                    "Laminage glacé",
                    "Mat UV",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            },
            {
                specName: "Perforation",
                values: [
                    "Non",
                    "Oui"
                ]
            }
        ]
    },
    {
        name: "Étiquettes en papier (Rentable)",
        desc: "Nos étiquettes en rouleaux de papier sont très populaires, faciles à utiliser dans diverses formes d'application et un moyen rapide de commander des modèles personnalisés à un prix raisonnable.",
        img: "images/etiquettes_en_rouleau/Paper-Roll-Labels.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "White Bright Paper Permanent",
                    "White Bright Paper Removable",
                    "White Opaque Paper Permanent",
                    "White Semi-Gloss Litho Paper Freezer Permanent",
                    "White Litho Paper Wet-Strength Permanent",
                    "White Classic Crest Paper Wet-strength Permanent",
                    "Estate #4 Paper Permanent",
                    "Silver Foil Paper Freezer Permanent",
                    "Pink Fluorescent Paper Permanent",
                    "Red Fluorescent Paper Permanent",
                    "Green Fluorescent Paper Permanent",
                    "Orange Fluorescent Paper Permanent",
                    "Yellow Fluorescent Paper Permanent",
                    "White Kraft Tamper Evident",
                ]
            },
            {
                specName: "Forme",
                values: [
                    "Cercle",
                    "Oval",
                    "Carré",
                    "Rectangle",
                    "Personnalisé",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "À spécifier"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Fini",
                values: [
                    "Aucun",
                    "Glacé UV",
                    "Laminage mat",
                    "Laminage glacé",
                    "Mat UV",
                ]
            },
            {
                specName: "Couleur de l'encre",
                values: [
                    "CMYK"
                ]
            },
            {
                specName: "Perforation",
                values: [
                    "Non",
                    "Oui"
                ]
            }
        ]
    },
]