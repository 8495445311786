export const chouxGeant = [
    {
        name: "Choux géant",
        desc: "",
        img: "images/choux_geant/chouxgeant.jpg",
        specs: [
            
            {
                specName: "Dimensions",
                values: [
                    "30\"",
                ]
            },
            {
                specName: "Couleur",
                values: [
                    "Rouge",
                    "Vert",
                    "Bleu",
                    "Noir",
                    "Argent"
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
                        
        ]
    },
    
]