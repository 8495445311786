export const tapisDeSouris = [
    {
        name: "Tapis de souris, base noire de 0,2\"",
        desc: "Imprimez des tapis de souris personnalisés sans minimum de commande avec les illustrations ou la marque de votre client. Ils mesurent 7,75\" x 9,25\" et environ 0,2\" d'épaisseur.",
        img: "images/tapis_de_souris/mouse_pad_new.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specName: "Taille",
                values: [
                    "9.25\" x 7.75\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]