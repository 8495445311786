import "../styles/CartEntry.css"

export default function CartEntry(props) {

    return (
        <div className="cart-entry">

            <div className="cart-entry-container">
                <div className="cart-entry-name">
                    {props.name}
                </div>

                <button className="product-remove-button"
                    onClick={
                        function (e) {
                            props.onRemove(props.product)

                            props.setNumProdsInCart(props.numProdsInCart - 1)
                        }
                    }>
                    <i class="xmark fa-solid fa-xmark"></i>
                </button>

                <div className="cart-entry-desc">

                    {
                        <ul className="specs-list">
                            {
                                Object.keys(props.productStates).map(function (keyName, keyIndex) {
                                    return (
                                        <>
                                            {props.productStates[keyName].value &&
                                                <li>{props.productStates[keyName].name}: {props.productStates[keyName].value}</li>
                                            }
                                        </>
                                    )
                                })
                            }
                        </ul>

                    }

                </div>

            </div>

        </div>
    )

}