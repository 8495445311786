export const bannieresEnVinyle = [
    {
        name: "Bannières en vinyle mat",
        desc: "Avec une finition qui réduit la réflection, les bannières en vinyle mat sont parfaites sous la lumière du soleil et l'éclairage lumineux.",
        img: "images/bannieres_en_vinyle/banner-matte.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle mat 13oz",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "12\" x 24\"",
                    "12\" x 36\"",
                    "12\" x 48\"",
                    "12\" x 60\"",
                    "24\" x 24\"",
                    "24\" x 36\"",
                    "24\" x 48\"",
                    "24\" x 60\"",
                    "31.5\" x 78.75\"",
                    "31.5\" x 81\"",
                    "31.5\" x 90\"",
                    "33.5\" x 78.75\"",
                    "35.43\" x 78.75\"",
                    "35.43\" x 81\"",
                    "35.43\" x 90\"",
                    "36\" x 36\"",
                    "36\" x 48\"",
                    "36\" x 60\"",
                    "39.37\" x 78.75\"",
                    "39.37\" x 81\"",
                    "39.37\" x 90\"",
                    "47.5 x 48.75",
                    "47.5 x 78.75",
                    "47.5 x 81",
                    "47.5 x 90",
                    "48 x 48",
                    "48 x 60",
                    "60 x 60",
                    "72 x 12",
                    "72 x 24",
                    "72 x 36",
                    "72 x 48",
                    "72 x 60",
                    "72 x 72",
                    "84 x 12",
                    "84 x 24",
                    "84 x 36",
                    "84 x 48",
                    "84 x 60",
                    "84 x 72",
                    "84 x 84",
                    "96 x 12",
                    "96 x 24",
                    "96 x 36",
                    "96 x 48",
                    "96 x 51",
                    "96 x 60",
                    "96 x 72",
                    "96 x 84",
                    "96 x 96",
                    "108 x 12",
                    "108 x 24",
                    "108 x 36",
                    "108 x 48",
                    "108 x 60",
                    "108 x 72",
                    "108 x 84",
                    "108 x 96",
                    "108 x 12",  
                    "120 x 12",
                    "120 x 24",
                    "120 x 36",
                    "120 x 48",
                    "120 x 60",
                    "120 x 72",
                    "120 x 84",
                    "120 x 96",
                    "144 x 12",
                    "144 x 24",
                    "144 x 36",
                    "144 x 48",
                    "144 x 60",
                    "144 x 72",
                    "144 x 84",
                    "144 x 96",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                ]
            },
            {
                specName: "Oeillets",
                values: [
                    "Aucun",
                    "2 coins supérieurs",
                    "4 coins",
                    "À chaque 2-3 pieds, haut et bas",
                    "À chaque 2-3 pieds, aux deux côtés",
                    "À chaque 2-3 pieds, tous les côtés",
                ]
            },
            {
                specName: "Ourlets",
                values: [
                    "Aucun",
                    "2 côtés les plus courts",
                    "2 côtés les plus longs",
                    "4 côtés",
                    "Haut et bas",
                    "Gauche et droite",
                ]
            }
            
        ]
    },
    {
        name: "Bannières en vinyle reluissant / glacé",
        desc: "Mettez le focus sur votre client avec nos bannières en vinyle reluissant ou glacé. Cette finition brillante illumine les images et les couleurs.",
        img: "images/bannieres_en_vinyle/banner-glossy.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "Vinyle reluissant 13oz",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "12\" x 24\"",
                    "12\" x 36\"",
                    "12\" x 48\"",
                    "12\" x 60\"",
                    "24\" x 24\"",
                    "24\" x 36\"",
                    "24\" x 48\"",
                    "24\" x 60\"",
                    "31.5\" x 78.75\"",
                    "31.5\" x 81\"",
                    "31.5\" x 90\"",
                    "33.5\" x 78.75\"",
                    "35.43\" x 78.75\"",
                    "35.43\" x 81\"",
                    "35.43\" x 90\"",
                    "36\" x 36\"",
                    "36\" x 48\"",
                    "36\" x 60\"",
                    "39.37\" x 78.75\"",
                    "39.37\" x 81\"",
                    "39.37\" x 90\"",
                    "47.5 x 48.75",
                    "47.5 x 78.75",
                    "47.5 x 81",
                    "47.5 x 90",
                    "48 x 48",
                    "48 x 60",
                    "60 x 60",
                    "72 x 12",
                    "72 x 24",
                    "72 x 36",
                    "72 x 48",
                    "72 x 60",
                    "72 x 72",
                    "84 x 12",
                    "84 x 24",
                    "84 x 36",
                    "84 x 48",
                    "84 x 60",
                    "84 x 72",
                    "84 x 84",
                    "96 x 12",
                    "96 x 24",
                    "96 x 36",
                    "96 x 48",
                    "96 x 51",
                    "96 x 60",
                    "96 x 72",
                    "96 x 84",
                    "96 x 96",
                    "108 x 12",
                    "108 x 24",
                    "108 x 36",
                    "108 x 48",
                    "108 x 60",
                    "108 x 72",
                    "108 x 84",
                    "108 x 96",
                    "108 x 12",  
                    "120 x 12",
                    "120 x 24",
                    "120 x 36",
                    "120 x 48",
                    "120 x 60",
                    "120 x 72",
                    "120 x 84",
                    "120 x 96",
                    "144 x 12",
                    "144 x 24",
                    "144 x 36",
                    "144 x 48",
                    "144 x 60",
                    "144 x 72",
                    "144 x 84",
                    "144 x 96",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                ]
            },
            {
                specName: "Oeillets",
                values: [
                    "Aucun",
                    "2 coins supérieurs",
                    "4 coins",
                    "À chaque 2-3 pieds, haut et bas",
                    "À chaque 2-3 pieds, aux deux côtés",
                    "À chaque 2-3 pieds, tous les côtés",
                ]
            },
            {
                specName: "Ourlets",
                values: [
                    "Aucun",
                    "2 côtés les plus courts",
                    "2 côtés les plus longs",
                    "4 côtés",
                    "Haut et bas",
                    "Gauche et droite",
                ]
            }
            
        ]
    },
    {
        name: "Bannière en filet",
        desc: "L’impression de bannières en filet produit de grandes bannières extérieures résistant au vent et à la pluie.",
        img: "images/bannieres_en_vinyle/mesh-banner.png",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "8 oz Polyester Mesh",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "12\" x 24\"",
                    "12\" x 36\"",
                    "12\" x 48\"",
                    "12\" x 60\"",
                    "24\" x 24\"",
                    "24\" x 36\"",
                    "24\" x 48\"",
                    "24\" x 60\"",
                    "31.5\" x 78.75\"",
                    "31.5\" x 81\"",
                    "31.5\" x 90\"",
                    "33.5\" x 78.75\"",
                    "35.43\" x 78.75\"",
                    "35.43\" x 81\"",
                    "35.43\" x 90\"",
                    "36\" x 36\"",
                    "36\" x 48\"",
                    "36\" x 60\"",
                    "39.37\" x 78.75\"",
                    "39.37\" x 81\"",
                    "39.37\" x 90\"",
                    "47.5 x 48.75",
                    "47.5 x 78.75",
                    "47.5 x 81",
                    "47.5 x 90",
                    "48 x 48",
                    "48 x 60",
                    "60 x 60",
                    "72 x 12",
                    "72 x 24",
                    "72 x 36",
                    "72 x 48",
                    "72 x 60",
                    "72 x 72",
                    "84 x 12",
                    "84 x 24",
                    "84 x 36",
                    "84 x 48",
                    "84 x 60",
                    "84 x 72",
                    "84 x 84",
                    "96 x 12",
                    "96 x 24",
                    "96 x 36",
                    "96 x 48",
                    "96 x 51",
                    "96 x 60",
                    "96 x 72",
                    "96 x 84",
                    "96 x 96",
                    "108 x 12",
                    "108 x 24",
                    "108 x 36",
                    "108 x 48",
                    "108 x 60",
                    "108 x 72",
                    "108 x 84",
                    "108 x 96",
                    "108 x 12",  
                    "120 x 12",
                    "120 x 24",
                    "120 x 36",
                    "120 x 48",
                    "120 x 60",
                    "120 x 72",
                    "120 x 84",
                    "120 x 96",
                    "144 x 12",
                    "144 x 24",
                    "144 x 36",
                    "144 x 48",
                    "144 x 60",
                    "144 x 72",
                    "144 x 84",
                    "144 x 96",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                ]
            },
            {
                specName: "Oeillets",
                values: [
                    "Aucun",
                    "2 coins supérieurs",
                    "4 coins",
                ]
            },
            {
                specName: "Ourlets",
                values: [
                    "Aucun",
                    "2 côtés les plus courts",
                    "2 côtés les plus longs",
                ]
            }
            
        ]
    },
]