export const sousVerres = [
    {
        name: "Sous-verres (paquets de 10)",
        desc: "Imprimez des sous-verres personnalisés qui protègent les meubles de vos clients des boissons chaudes. Ces sous-verres personnalisés de 0,22\" viennent en cercle et carré. La sélection de la quantité 1 vous donnera 10 pièces.",
        img: "images/sous_verres/coaster_product_1.png",
        specs: [
            
            {
                specName: "Couleur",
                values: [
                    "Blanc",
                ]
            },
            {
                specname: "Taille",
                values: [
                    "3.75\" x 3.75\""
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "À spécifier"
                ]
            },
            
            
        ]
    },
]