import React from 'react'
import "../styles/Contact.css"
import "../styles/Modal.css"

export const QuoteForm = (props) => {

  const buildProductString = (product) => {

    const productName = product.name
    const statesText = Object.keys(product.states).map(function (keyName, keyIndex) {
      if (product.states[keyName].value) {
        return (
          product.states[keyName].name + ": " + product.states[keyName].value + "\n"
        )
      }
    })

    console.log(productName)
    console.log(statesText)
    return ("\n" + productName + "\n" + statesText + "\n")
  }

  return (

    <div className="contact-container">

      <form className="contact-form" action="https://formsubmit.co/printzonepromo@gmail.com" method="POST">
        <h3>Ajoutez vos coordonnées et votre message pour obtenir un devis pour les produits choisis.</h3>
        <input type="text" name="Name" id="name" placeholder="Votre nom" required />
        <input type="email" name="Email" id="email" placeholder="Votre email" required />
        <textarea name="Message" id="Message" rows="4" placeholder="Votre message"></textarea>
        <button className="modal-add-button" type="submit">Envoyer votre demande</button>
        <input type="hidden" name="Requested Products" value={

          props.cartItems.map(product => {
            return (
              // product.name + "\n" 
              // product.states.map(state => {
              //   return (state.name + ": " + state.value)
              // })
              buildProductString(product)
            )
          })


        } />
      </form>

    </div>
  )
}
