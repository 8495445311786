export const signets = [
    {
        name: "Signet avec fini mat",
        desc: "Le signet avec fini mat est velouté et lisse au toucher. Choisissez un fini mat pour une option élégante et économique.",
        img: "images/signets/matte.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "10pt + fini mat",
                    "14pt + fini mat",
                    "16pt + fini mat",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côté (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "1.5 x 7",
                    "2.5 x 8.5",
                    "2 x 7",
                    "2 x 8",
                    "2.75 x 8.5",
                    "3 x 4",
                    "3.5 x 8.5",
                    "3.66 x 4.25",
                    "3.66 x 8.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Fini mat"
                ]
            }
        ]
    },
    {
        name: "Signet avec fini glacé UV",
        desc: "Impressionner avec un fini glacé et brillant. Les signets glacés sont une bonne option pour les images éclatantes et colorées.",
        img: "images/signets/highgloss.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "14pt avec fini UV (glacé)",
                    "16pt avec fini UV (glacé)",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côté (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "1.5 x 7",
                    "2.5 x 8.5",
                    "2 x 7",
                    "2 x 8",
                    "2.75 x 8.5",
                    "3 x 4",
                    "3.5 x 8.5",
                    "3.66 x 4.25",
                    "3.66 x 8.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "UV PG 1 seulement"
                ]
            }
        ]
    },
    {
        name: "Signet avec laminé mat",
        desc: "Pour un signet plus durable, choisissez l’option laminé mat ou glacé pour une protection additionnelle qui apparaît bien.",
        img: "images/signets/lamination.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "18pt",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côté (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "1.5 x 7",
                    "2.5 x 8.5",
                    "2 x 7",
                    "2 x 8",
                    "2.75 x 8.5",
                    "3 x 4",
                    "3.5 x 8.5",
                    "3.66 x 4.25",
                    "3.66 x 8.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Laminé mat (1 côté)",
                    "Laminé mat (2 côtés)",
                    "Laminé glacé (1 côté)",
                    "Laminé glacé (2 côtés)",
                ]
            }
        ]
    },
    {
        name: "Signet spécial",
        desc: "Choisissez à partir d’un choix de stock et fini unique qui convient à la conception du signet personnalisé de votre client.",
        img: "images/signets/specialty.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "13pt Enviro sans enduit",
                    "13pt en Lin sans enduit",
                    "14pt Écrivable + UV (C1S)",
                    "18pt laminé mat + Verni sélectif UV",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "1 côté (4/0)",
                    "2 côté (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "1.5 x 7",
                    "2.5 x 8.5",
                    "2 x 7",
                    "2 x 8",
                    "2.75 x 8.5",
                    "3 x 4",
                    "3.5 x 8.5",
                    "3.66 x 4.25",
                    "3.66 x 8.5",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            }
        ]
    },
]