export const calendriersMuraux = [
    {
        name: "Gloss Text",
        desc: "Nos calendriers en gros assurent des images éclatantes tout en offrant une surface écrivable.",
        img: "images/calendriers_muraux/gloss.jpg",
        specs: [
            {
                specName: "Matériel",
                values: [
                    "80lb Gloss Text",
                    "100lb Gloss Text",
                ]
            },
            {
                specName: "Côtés imprimés",
                values: [
                    "2 côtés (4/4)"
                ]
            },
            {
                specName: "Taille",
                values: [
                    "8.5 x 11",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "100",
                    "250",
                    "500",
                    "750",
                    "1000",
                    "2500",
                ]
            },
            {
                specName: "Enduit",
                values: [
                    "Sans enduit"
                ]
            },
            {
                specName: "Pages",
                values: [
                    "28pg auto-couverture",
                    "24pg + couverture"
                ]
            }
        ]
    },
]