export const dancingGuys = [
    {
        name: "Dancing guys",
        desc: "",
        img: "images/dancing_guys/dancingguys.jpg",
        specs: [
            
            {
                specName: "Hauteur",
                values: [
                    "18 pieds",
                ]
            },
            {
                specName: "Couleur",
                values: [
                    "Bleu",
                    "Rouge",
                    "Vert",
                    "Jaune",
                ]
            },
            {
                specName: "Quantité",
                values: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                ]
            },
            {
                specName: "Inclure blower HD haute performance",
                values: [
                    "Oui",
                    "Non",
                ]
            },
            
        ]
    },
    
]